import React from "react"
import data from "../../data/css-selectors.json"
import "./SelectorsTable.scss"

const SelectorsTable = () => (
  <table className="selectors__table">
    <tbody>
      <tr>
        <th>Selektor</th>
        <th>Przykład</th>
        <th>Opis</th>
      </tr>
      {data.map((item, index) => (
        <tr key={index}>
          <td>{item.selector}</td>
          <td>{item.example}</td>
          <td>{item.description}</td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default SelectorsTable
