import React from "react"
import Seo from "../components/Seo/Seo"
import PageHeader from "../components/PageHeader/PageHeader"
import Footer from "../components/Footer/Footer"
import Container from "../components/Container/Container"
import Box from "../components/Box/Box"
import SelectorsTable from "../components/SelectorsTable/SelectorsTable"

const Selecotrs = ({ location }) => {
  return (
    <>
      <Seo
        title={`Selektory CSS | CSSGENERATOR.PL`}
        description="Selektory CSS | Wygodne narzędzie, dzięki któremu wygenerujesz gotowe reguły CSS. Dostosuj wartości i skopiuj gotowy kod."
        location={location}
      />
      <PageHeader
        h1="Selektory CSS"
        desc="Selektory CSS służą do wybierania elementów HTML, które chcesz ostylować."
        notGenerator
      />

      <Container>
        <div className="description">
          <Box>
            <h2>Czym są selektory CSS?</h2>
            <p>
              Selektory CSS to elementy{" "}
              <a href="/blog/co-to-jest-css/">języka CSS</a>, które pozwalają na
              określanie elementów HTML, do których mają być stosowane reguły
              CSS. Selektory pozwalają na precyzyjne wybieranie elementów na
              stronie, takich jak określone elementy HTML, klasy, identyfikatory
              lub atrybuty. Dzięki temu, można zdefiniować reguły CSS, które
              będą odnoszone tylko do określonych elementów na stronie, bez
              wpływu na inne elementy.
            </p>

            <p>
              Istnieje wiele różnych rodzajów selektorów, takich jak selektory
              elementów, klas, identyfikatorów, atrybutów i wiele innych. Każdy
              z nich pozwala na różne sposoby selekcji elementów na stronie i
              jest używany w różnych sytuacjach, aby uzyskać odpowiedni wygląd i
              formatowanie. Selektory CSS są bardzo ważnym elementem języka i
              pozwalają na tworzenie precyzyjnie zdefiniowanych stylów, które
              dokładnie odpowiadają wymaganiom witryny.
            </p>
          </Box>
        </div>
        <div className="selectors__wrapper">
          <Box>
            <h2>Slektory CSS</h2>
            <SelectorsTable />
          </Box>
          <i>źródło: </i>{" "}
          <a
            href="https://www.w3schools.com/cssref/css_selectors.asp"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <i>https://www.w3schools.com/cssref/css_selectors.asp</i>
          </a>
        </div>
      </Container>

      <Footer />
    </>
  )
}

export default Selecotrs
